import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Container, Layout } from 'layout';

import 'styles/main.scss';

const NotFoundPage = () => {
  const {
    pageNotFound: { text },
  } = useStaticQuery(graphql`
    {
      pageNotFound {
        text
      }
    }
  `);

  return (
    <Layout>
      <section className="not-found container-wrapper">
        <div className="not-found__holder">
          <Container fluid className="not-found__content">
            <div className="not-found__content-text">
              <p>{text}</p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
